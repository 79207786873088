import Header from "./Header";
import Footer from "./Footer";
import Head from "next/head";

interface LayoutProps {
  title: string;
  children: React.ReactNode;
  isFullWidth?: boolean;
  hideNav?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  children,
  isFullWidth,
  hideNav,
}) => {
  return (
    <>
      <Head>
        <title>{title} :: Cuter.art</title>
      </Head>
      <div className="bg-slate-800 text-gray-200 min-h-screen">
        <Header title={title} hideNav={hideNav} />
        <main className={`${isFullWidth ? "mx-4" : "m-auto w-9/12"}`}>
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
