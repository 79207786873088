import _ from "lodash";
import type { Token, User } from "@prisma/client";
import { GetServerSideProps, NextPage } from "next";
import type { Session } from "next-auth";

import Landing from "components/Landing";
import Layout from "components/Layout";

type HomeProps = {
  user: User | null;
  session: Session | null;
  userTokens: Token[];
  tokens: Token[];
};

const Home: NextPage<HomeProps> = ({ session, tokens, userTokens }) => {
  return (
    <Layout
      title={session ? "Browse for matches" : "Welcome!"}
      isFullWidth={!!session}
    >
      <Landing tokens={tokens} />
    </Layout>
  );
};

export default Home;
