import React from "react";
import { Token } from "@prisma/client";

type LandingProps = {
  tokens: Token[];
};

const Landing: React.FC<LandingProps> = () => {
  return <div className="flex flex-col justify-center items-center"></div>;
};

export default Landing;
